import React from "react"
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const ContactPage = ({ intl }) => (
  <Layout>
    <SEO lang={intl.locale} title={intl.formatMessage({ id: "menu.contact" })}/>
    <div className="bg-primary text-center p-5 text-white font-weight-bold">
      <h1 className="m-4">
        <FormattedMessage id="contact.title"/>
      </h1>
    </div>

    <div className="pt-5 pb-4 bg-secondary text-center">
      <h2><i className="las la-envelope-open"/> <a href="mailto:info@mlj.solutions">info@mlj.solutions</a></h2>
    </div>

    <div className="py-5 bg-light">
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-12 col-lg-6 text-lg-right">
            <div className="mr-lg-5">
              <h3>MLJ solutions a.s.</h3>
              <p>Školská 689/20<br/>Nové Město<br/>110 00 Praha 1</p>
              <p>IČO: 08579156</p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex mt-5 mt-lg-0">
              {/*<div className="person-photo" style={{width: '100px', height: '100px'}}>*/}
              {/*  <Image which="photoMichal"/>*/}
              {/*</div>*/}
              <div className="ml-4 my-2">
                <h5 className="font-weight-bold mb-1">Ing. Michal Dobrovolný</h5>
                <p className="small text-muted font-weight-bold">Chief Executive Officer</p>
                <p className="small">
                  <i className="las la-envelope-open"/> <a href="mailto:dobrovolny@mlj.solutions">dobrovolny@mlj.solutions</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="pb-4 bg-light">
      <div className="container">
        <Link to="/">
          <i className="las la-angle-left"/>
          {intl.formatMessage({ id: "go_back" })}
        </Link>
      </div>
    </div>
  </Layout>
);

export default injectIntl(ContactPage)
